import * as R from 'ramda'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Step,
  StepButton,
  StepContent,
  Stepper,
  TextField,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ChipInput from 'material-ui-chip-input'
import FsLightbox from 'fslightbox-react'
import crypto from 'crypto-js'

import { PictureAsPdf } from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import Swal from 'sweetalert2'

import { mapIndexed, nilOrEmpty, notNilOrEmpty } from '../../lib/Helpers'
import BusinessCard from '../../services/BusinessCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BusinessCardDropdown from './BusinessCardsDropdown'

const INITIAL_BC_STATE = {
  title: '',
  company_phone: '',
  company_primary_contact: '',
  company_primary_contact_email: '',
  company_url: '',
  company_img: null,
  company_img_name: '',
  solution_id: '',
  sbir_program: '',
  sttr_program: '',
  afwerx_challenge_submission: '',
  bc__who_are_you: ''
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  divider: {
    margin: '0 1rem'
  },
  companyImage: {
    width: '100px',
    height: '100px',
    margin: 'auto',
    marginBottom: '1rem'
  },
  exhibitorImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column'
  },
  filesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    flexWrap: 'wrap'
  },
  fileContainer: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '0.5rem'
  },
  fileIcon: {
    margin: 'auto',
    marginBottom: '1rem'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(255,255,255,0.7)',
    zIndex: 1000,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  loader: {
    margin: 'auto'
  }
}))

const findIndex = (arr, val) => R.findIndex(R.propEq('_id', val))(arr)

const getFormInitialState = businessCard => {
  if (businessCard === 'new' || !businessCard) {
    return INITIAL_BC_STATE
  }

  return {
    company_phone: businessCard.company_phone || '',
    company_primary_contact: businessCard.company_primary_contact || '',
    company_primary_contact_email:
      businessCard.company_primary_contact_email || '',
    company_url: businessCard.company_url || '',
    sbir_program: businessCard.sbir_program || '',
    sttr_program: businessCard.sttr_program || '',
    afwerx_challenge_submission: businessCard.afwerx_challenge_submission || '',
    ...businessCard
  }
}

export default ({
  client,
  session,
  onSignOut,
  setUserBusinessCard,
  businessCard,
  businessCards,
  fetchBusinessCards,
  ...props
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoadingState] = React.useState(false)
  const [cats, setCats] = React.useState([])
  const [challengeType, setChallengeType] = React.useState({})
  const [availableSubCats, setAvailableSubcats] = React.useState(false)
  const [formData, setFormData] = React.useState(
    getFormInitialState(businessCard)
  )
  const [activeStep, setActiveStep] = React.useState(0)
  const [selectedChallengeType, setSelectedChallengeType] = React.useState('')
  const [videoHelp, toggleVideoHelp] = React.useState(false)
  const [zoomHelp, toggleZoomHelp] = React.useState(false)

  React.useEffect(() => {
    const el =
      typeof document !== 'undefined' && document.getElementById('bc-form')
    typeof document !== 'undefined' &&
      window.scroll({ top: el.offsetTop - 150, behavior: 'smooth' })
  }, [])

  React.useEffect(() => {
    const shouldDefineCategoriesFromVendor =
      formData._id != null &&
      formData.challengeType != null &&
      challengeType.length > 0
    if (shouldDefineCategoriesFromVendor) {
      const catIndex = findIndex(challengeType, formData.challengeType)
      if (catIndex in challengeType && challengeType[catIndex].categories) {
        setSelectedChallengeType(challengeType[catIndex].title)
        setCats(challengeType[catIndex].categories)
      }
    }
  }, [formData._id, formData._rev])

  React.useEffect(() => {
    const isDifferentRevision =
      '_rev' in formData &&
      businessCard != null &&
      businessCard._rev !== formData._rev
    const isNewBusinessCard =
      !('_id' in formData) && businessCard != null && businessCard._id != null
    if (isDifferentRevision || isNewBusinessCard) {
      setFormData(getFormInitialState(businessCard))
    }
  }, [businessCard])

  function _handleInputChange(e) {
    e.preventDefault()
    if (
      R.or(
        R.equals(e.target.name, 'solution_id'),
        R.equals(e.target.name, 'company_address_zipcode')
      ) &&
      e.target.value.length > 5
    ) {
      return
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    if (e.target.name === 'category')
      if (notNilOrEmpty(cats[findIndex(cats, e.target.value)].subcategories)) {
        setAvailableSubcats(true)
      } else {
        setAvailableSubcats(false)
      }
  }

  function _handleAddingImage(id, e) {
    const name = `${id}_name`
    notNilOrEmpty(e) &&
      setFormData({
        ...formData,
        [id]: e[0],
        [name]: e[0].name
      })
  }

  async function _handleForm(e) {
    try {
      e.preventDefault()
      setIsLoadingState(true)
      // creating the object to send to sanity
      const nextBusinessCard = new BusinessCard(
        formData,
        client,
        session.user._id,
        getFormInitialState(businessCard)
      )
      const businessCardRecord = await (nextBusinessCard.isNew()
        ? nextBusinessCard.createBusinessCard()
        : nextBusinessCard.updateBusinessCard())

      Swal.fire({
        title: 'Thank you for your Virtual Business Card submission.',
        text:
          'We will review it and publish it to the site as soon as possible.',
        icon: 'success',
        showConfirmButton: false,
        confirmButtonText: 'Send',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setUserBusinessCard(businessCardRecord)
      setActiveStep(0)
      setIsLoadingState(false)
      fetchBusinessCards()
    } catch (error) {
      console.error('ERROR', error)
      setIsLoadingState(false)
      Swal.fire({
        title: 'Failed to save your information.',
        text: 'Please try again or contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
    }
  }

  const removeBusinessCardResource = (unset, resourceType) => {
    setIsLoadingState(true)
    client
      .patch(formData._id)
      .unset(unset)
      .commit()
      .then(async res => {
        const businessCardRecord = new BusinessCard({}, client, null, {})
        const updatedBusinessCard = await businessCardRecord.getLatestDocument(
          formData._id
        )
        setUserBusinessCard(updatedBusinessCard)
        setIsLoadingState(false)
        Swal.fire({
          title: 'Record Deleted',
          text: `The requested ${resourceType} was deleted successfuly.`,
          icon: 'success',
          showConfirmButton: false,
          confirmButtonText: 'Send',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
      .catch(err => {
        console.error('Delete failed: ', err.message)
        setIsLoadingState(false)
        Swal.fire({
          title: `Failed to delete the requested ${resourceType}.`,
          text: 'Please try again or contact support!',
          icon: 'error',
          showConfirmButton: false,
          confirmButtonText: '',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
  }

  function _createNewBusinessCard() {
    setUserBusinessCard('new')
    setActiveStep(0)
    setFormData(getFormInitialState('new'))
  }

  return (
    <div className="container exhibitor-page page--new-exhibitor">
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
      <div
        className={`animated fadeIn section-content form-container-alignment`}
      >
        {notNilOrEmpty(session) && notNilOrEmpty(session.user) ? (
          <Box
            display="flex"
            p={1}
            flexDirection="row"
            alignItems="center"
            justifyItems="space-between"
            className="form-top-section"
          >
            <div className="form-top-section--left">
              <Typography variant="caption">
                Signed In As: {session.user.email}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
              <Typography variant="caption">
                <Link href="#" onClick={onSignOut}>
                  Sign Out
                </Link>
              </Typography>
            </div>
            <div className="form-top-section--right">
              <Button
                onClick={_createNewBusinessCard}
                className="btn--yellow"
                variant="contained"
                color="primary"
              >
                Add New
              </Button>
              {notNilOrEmpty(session.user) && notNilOrEmpty(businessCards) && (
                <BusinessCardDropdown
                  businessCards={businessCards}
                  selectedBusinessCardId={businessCard._id}
                  setSelectedBusinessCard={setUserBusinessCard}
                />
              )}
            </div>
          </Box>
        ) : null}
        <div className="form-container">
          <form onSubmit={_handleForm} id="bc-form">
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Company / Team Name"
                variant="outlined"
                color="secondary"
                name="title"
                onChange={_handleInputChange}
                value={formData.title}
                required
                error={
                  R.equals(businessCard, 'new')
                    ? R.gt(R.length(formData.title), 79)
                    : null
                }
                helperText={
                  R.equals(businessCard, 'new')
                    ? `(79 characters limit) / ${79 -
                        R.length(formData.title)} chars left`
                    : ''
                }
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                error={
                  formData.solution_id && formData.solution_id.length > 0
                    ? R.lt(R.length(formData.solution_id), 4)
                    : false
                }
                helperText={
                  formData.solution_id &&
                  formData.solution_id.length > 0 &&
                  R.lt(R.length(formData.solution_id), 4)
                    ? 'Your solution identifier must be 4 or 5 digits'
                    : 'If you participated in the Space Challenge, enter your solution identifier.'
                }
                label="Your Space Solution ID"
                variant="outlined"
                color="secondary"
                name="solution_id"
                onChange={_handleInputChange}
                value={formData.solution_id}
                type="number"
                inputProps={{
                  maxLength: 5,
                  minLength: 4
                }}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                helperText={`Please briefly tell us about your solution / company (150 char limit) / ${150 -
                  R.length(formData.bc__who_are_you)} chars left`}
                label="Brief Summary of Solution / Company"
                variant="outlined"
                color="secondary"
                name="bc__who_are_you"
                onChange={_handleInputChange}
                value={formData.bc__who_are_you}
                multiline
                rows={8}
                required
                error={R.gte(
                  R.length(
                    formData.bc__who_are_you ? formData.bc__who_are_you : 0
                  ),
                  150
                )}
              />
            </FormControl>
            <InputLabel required className="form-input" id="logo-label">
              Team Logo
            </InputLabel>
            {formData._id != null && formData.company_img_url != null && (
              <div className={classes.exhibitorImageContainer}>
                <img
                  src={formData.company_img_url}
                  alt="Company Image"
                  className={classes.companyImage}
                />
                <Button
                  color="secondary"
                  onClick={() => removeBusinessCardResource(['company_img'])}
                >
                  Remove
                </Button>
              </div>
            )}
            <FormControl variant="outlined" className="form-input">
              <DropzoneArea
                required
                acceptedFiles={['image/*']}
                dropzoneText={'Drag and drop your team logo here or click'}
                filesLimit={1}
                id="image-path"
                name="company_img"
                onChange={e => _handleAddingImage('company_img', e)}
                initialFiles={[formData.company_img]}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                color="secondary"
                error={
                  notNilOrEmpty(formData.company_url) &&
                  !R.startsWith('http', formData.company_url)
                }
                helperText="Please include http:// or https://"
                label="Company Website"
                name="company_url"
                onChange={_handleInputChange}
                value={formData.company_url}
                variant="outlined"
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Primary Contact"
                variant="outlined"
                color="secondary"
                name="company_primary_contact"
                onChange={_handleInputChange}
                value={formData.company_primary_contact}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Primary Contact Email"
                variant="outlined"
                color="secondary"
                type="email"
                name="company_primary_contact_email"
                error={
                  notNilOrEmpty(formData.company_primary_contact_email) &&
                  R.not(
                    notNilOrEmpty(
                      R.match(
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        formData.company_primary_contact_email
                      )
                    )
                  )
                }
                onChange={_handleInputChange}
                value={formData.company_primary_contact_email}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label"
                label="Phone Number"
                variant="outlined"
                color="secondary"
                name="company_phone"
                onChange={_handleInputChange}
                value={formData.company_phone}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label small-font"
                color="secondary"
                label="Has your company been selected for the Small Business Innovation Research (SBIR) program?"
                name="sbir_program"
                onChange={_handleInputChange}
                select
                value={formData.sbir_program}
                variant="outlined"
              >
                <MenuItem value="Yes">
                  <ListItemText primary="Yes" />
                </MenuItem>
                <MenuItem value="No">
                  <ListItemText primary="No" />
                </MenuItem>
              </TextField>
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label small-font"
                color="secondary"
                label="Has your company been selected for the Small Business Technology Transfer (STTR) program?"
                name="sttr_program"
                onChange={_handleInputChange}
                select
                value={formData.sttr_program}
                variant="outlined"
              >
                <MenuItem value="Yes">
                  <ListItemText primary="Yes" />
                </MenuItem>
                <MenuItem value="No">
                  <ListItemText primary="No" />
                </MenuItem>
              </TextField>
            </FormControl>
            <FormControl variant="outlined" className="form-input">
              <TextField
                className="search-box-label small-font"
                color="secondary"
                label="Have you/your company ever submitted to an AFWERX Challenge?"
                name="afwerx_challenge_submission"
                onChange={_handleInputChange}
                select
                value={formData.afwerx_challenge_submission}
                variant="outlined"
              >
                <MenuItem value="Yes">
                  <ListItemText primary="Yes" />
                </MenuItem>
                <MenuItem value="No">
                  <ListItemText primary="No" />
                </MenuItem>
              </TextField>
            </FormControl>
            <div className={classes.actionsContainer}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={_handleForm}
                  className={classes.button}
                  disabled={R.not(
                    R.and(
                      R.and(
                        R.and(
                          notNilOrEmpty(formData.title),
                          R.cond([
                            [
                              R.equals('new'),
                              R.always(R.lte(R.length(formData.title), 79))
                            ],
                            [R.is(Object), R.always(true)],
                            [R.T, R.T]
                          ])(businessCard)
                        ),
                        R.and(
                          notNilOrEmpty(formData.bc__who_are_you),
                          R.lte(
                            R.length(
                              formData.bc__who_are_you
                                ? formData.bc__who_are_you
                                : 0
                            ),
                            150
                          )
                        )
                      ),
                      notNilOrEmpty(formData.company_img)
                    )
                  )}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
