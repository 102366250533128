import React from 'react'
import sanityClient from '@sanity/client'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import LoginHoc from '../hocs/LoginHoc'
import BusinessCardSelection from '../components/BusinessCards/BusinessCardSelection'

const client = sanityClient({
  projectId: process.env.GATSBY_SANITY_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
  token: process.env.GATSBY_SANITY_TOKEN,
  useCdn: false // `false` if you want to ensure fresh data
})

const titleTypeConfig = {
  new: {
    seo:
      'EngageSpace Networking Directory Registration | Afwerx Space Challenge Virtual Showcase',
    hero: 'EngageSpace Networking Directory Registration'
  },
  edit: {
    seo:
      'Edit EngageSpace Networking Directory | Afwerx Space Challenge Virtual Showcase',
    hero: 'Edit EngageSpace Networking Directory Management'
  },
  selection: {
    seo:
      'EngageSpace Networking Directory | Afwerx Space Challenge Virtual Showcase',
    hero: 'EngageSpace Networking Directory'
  }
}

export default () => {
  const [titleType, setTitleType] = React.useState('selection')

  const setIsNewBusinessCardState = React.useCallback(
    user => {
      if (user.businessCard != null && user.businessCard._id != null) {
        setTitleType('edit')
        return
      }

      if (
        Array.isArray(user.businessCards) &&
        user.businessCards.length > 0 &&
        !user.businessCard
      ) {
        setTitleType('selection')
        return
      }

      setTitleType('new')
    },
    [titleType]
  )

  return (
    <Layout>
      <SEO title={titleTypeConfig[titleType].seo} />
      <Hero
        title={titleTypeConfig[titleType].hero}
        className="exhibitor-page"
        img={require('../assets/imgs/5.jpg')}
      />
      <LoginHoc
        sanityClient={client}
        onSuccessfulAuthentication={setIsNewBusinessCardState}
        onSignOut={() => setTitleType('new')}
      >
        <BusinessCardSelection
          client={client}
          onSuccessfullFirstBusinessCardCreation={setIsNewBusinessCardState}
        />
      </LoginHoc>
    </Layout>
  )
}
