import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  ListItemText,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@material-ui/core'

import { mapIndexed, notNilOrEmpty } from '../../lib/Helpers'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '14rem'
  }
}))

export default ({
  selectedBusinessCardId,
  businessCards,
  setSelectedBusinessCard
}) => {
  const classes = useStyles()

  const selectBusinessCard = e =>
    businessCards.find(bc => bc._id === e.target.value)

  const _handleBusinessCardSelectChange = e => {
    e.preventDefault()
    setSelectedBusinessCard(selectBusinessCard(e))
  }

  return (
    <FormControl
      size="small"
      variant="outlined"
      className={`form-input ${classes.formControl}`}
    >
      <InputLabel>Registered Company</InputLabel>
      <Select
        label="Registered Company"
        name="businessCard"
        onChange={_handleBusinessCardSelectChange}
        required
        defaultValue={selectedBusinessCardId}
      >
        {notNilOrEmpty(businessCards) &&
          mapIndexed((bc, index) => {
            return (
              <MenuItem key={index} value={bc._id}>
                <ListItemText primary={bc.title} />
              </MenuItem>
            )
          })(businessCards)}
      </Select>
    </FormControl>
  )
}
